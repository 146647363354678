import 'bootstrap/dist/css/bootstrap.css'
import './src/styles/fontawsome.all.min.css'
import './src/styles/common.sass'

import 'jquery/dist/jquery.min.js'
import 'popper.js/dist/popper.min'
import 'bootstrap/dist/js/bootstrap.min.js'

import $ from 'jquery'

// in gastby-browser.js
export function shouldUpdateScroll({
    routerProps: { location },
    getSavedScrollPosition,
}) {
    if (location.hash.length > 0) {
        // console.log(location)

        let topVal = $(location.hash).offset().top
        window.scrollTo(0, topVal - 97)

        return false
    }
}
